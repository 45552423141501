// Custom variables :: Colors
//
// -> bk : black;
// -> gy : grey;
// -> wh : white;
// -> pu : purple;
// -> bl : blue;
// -> br : brown;
// -> or : orange;
// -> gn : green;
// -> pk : pink;
$bk-a: #000000;
$wh-a: #FFFFFF;
$gy-a: #eeeeee;
$gy-b: #3c3c3c;
$gy-c: #0d0604;
$gy-d: #717171;
$gy-e: #f2f2f2;
$rd-a: #d4002a;
$rd-b: #d4002a40;
// Color
.c-bk-a {
  color: $bk-a;
}

.c-wh-a {
  color: $wh-a;
}

.c-gy-a {
  color: $gy-a;
}

.c-gy-b {
  color: $gy-b;
}

.c-gy-c {
  color: $gy-c;
}

.c-rd-a {
  color: $rd-a;
}
// Background

.bg-bk-a {
  background-color: $bk-a;
}

.bg-wh-a {
  background-color: $wh-a;
}

.bg-gy-a {
  background-color: $gy-a;
}

.bg-gy-b {
  background-color: $gy-b;
}

.bg-gy-c {
  background-color: $gy-c;
}

.bg-rd-a {
  background-color: $rd-a;
}
