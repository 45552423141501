#reassure-wp {
	text-align: center;
	.item {
		width: px(200);
		display: inline-block;
		vertical-align: top;
	}
	.title {
		margin-top: px(10);
		color: $wh-a;
		font-size: px(15);
		font-weight: 700;
		line-height: 1.3em;
	}
	@include media-breakpoint-down(lg) {
		.item {
			width: px(175);
		}
 	}
	@include media-breakpoint-down(md) {
		.item {
			width: px(135);
		}
 	}
	@include media-breakpoint-down(sm) {
		.item {
			width: px(97);
		}
		.sprite {
			transform: scale(0.7);
		}
		.sprite.icon-reassure-4 {
			transform: scale(0.7) translatex(-15px);
		}
		.title {
			font-size: px(13);
			margin-top: 0;
		}
 	}
	@include media-breakpoint-down(xs) {
		.item {
			width: 100%;
			height: auto;
			br {
				display: none;
			}
		}
		.title {
			font-size: px(14);
		}
 	}
}

#footer-top {
	.container {
		padding-left: px(200);
		@include media-breakpoint-down(lg) {
			padding-left: px(120);
		}
		@include media-breakpoint-down(sm) {
			padding-left: px(15);
		}

	}
	.title {
		display: block;
		position: relative;
		padding-left: px(20);
		color: $gy-b;
		font-size: px(14);
		margin: 0;

		&:before {
			content: "";
			position: absolute;
			top: px(3);
			left: 0;
			@extend .sprite;
			@extend .arrow-bullet;
		}
	}

	.list-bullet {
		li {
			display: block;
			line-height: 1.3em;

			&:not(:last-child) {
				margin-bottom: px(5);
			}

			a {
				display: block;
				position: relative;
				padding-left: px(20);
				font-size: px(14);
				color: $gy-b;

				&:before {
					content: "";
					position: absolute;
					top: px(5);
					left: 0;
					@extend .sprite;
					@extend .arrow-bullet;
				}
			}
		}
	}
	@include media-breakpoint-down(xs) {
		.sprite.icon-payment {
			transform: scale(0.8) translateX(-35px);
		}
	}
}

#footer-bottom {
	text-align: center;
	color: $wh-a;

	a {
		color: $wh-a;
		font-weight: 400;
	}

	.pixell-wp {
		display: inline-block;
		margin-left: px(50);
	}

	.pixell {
		font-size: px(12);

		img {
			width: 30px;
			display: inline-block;
			position: relative;
			top: -4px;
			margin-left: 5px;
		}
	}

	.list {
		margin-right: px(50);
		display: inline-block;

		li {
			display: inline-block;
			font-size: px(12);

			&:not(:last-child):after {
				content: "|";
			}
		}
	}
	@include media-breakpoint-down(md) {
		.pixell-wp {
			margin-left: px(25);
		}

		.list {
			margin-right: px(25);
		}
	}
	@include media-breakpoint-down(xs) {
		.pixell-wp {
			display: block;
			margin-left: px(0);
		}

		.list {
			display: block;
			margin-right: px(0);
		}
	}
}
