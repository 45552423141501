// Sprite :: Structure

.sprite {
	display: inline-block;
	background-image: url("../images/icons/sprite.png");
	background-repeat: no-repeat;
	vertical-align: text-top;
	background-size: 1000px;

	&.icon-facebook,
	&.icon-instagram {
		width: 40px;
		height: 40px;
	}

	&.icon-facebook {
		background-position: 0 0;
	}

	&.icon-instagram {
		background-position: -40px 1px;
	}

	&.icon-phone,
	&.icon-user {
		width: 30px;
		height: 30px;
	}

	&.icon-phone-xs {
		width: 15px;
		height: 15px;
		background-position: -220px -1px;
		vertical-align: middle;
	}

	&.icon-user {
		background-position: -80px -1px;
	}

	&.icon-phone {
		background-position: -110px 0;
	}

	&.flag-us,
	&.flag-fr {
		width: 25px;
		height: 15px;
	}

	&.flag-fr {
		background-position: -140px 0;
	}

	&.flag-us {
		background-position: -140px -15px;
	}

	&.arrow-bullet {
		width: 10px;
		height: 10px;
		background-position: -165px 0;
	}

	&.arrow-dropdown-black,
	&.arrow-dropdown-white {
		width: 15px;
		height: 10px;
	}

	&.arrow-dropdown-black {
		background-position: -175px 0;
	}

	&.arrow-dropdown-white {
		background-position: -175px -10px;
	}

	&.arrow-dropdown-black-lg {
		width: 20px;
		height: 10px;
		background-position: -175px -20px;
	}

	&.arrow-more-white-lg {
		width: 25px;
		height: 40px;
		background-position: -195px 0;
	}

	&.icon-payment {
		width: 285px;
		height: 50px;
		background-position: 0 -40px;
	}

	&.icon-reassure-1,
	&.icon-reassure-2,
	&.icon-reassure-3,
	&.icon-reassure-4,
	&.icon-reassure-5 {
		width: 80px;
		height: 90px;
	}

	&.icon-reassure-1 {
		background-position: 0 -90px;
	}

	&.icon-reassure-2 {
		background-position: -80px -90px;
	}

	&.icon-reassure-3 {
		background-position: -160px -90px;
	}

	&.icon-reassure-4 {
		width: 115px;
		background-position: -240px -90px;
	}

	&.icon-reassure-5 {
		background-position: -355px -90px;
	}

	&.filter-all,
	&.filter-cat-1,
	&.filter-cat-2,
	&.filter-cat-3,
	&.filter-cat-4 {
		width: 30px;
		height: 20px;
	}

	&.filter-all {
		background-position: -360px 0;
	}

	&.filter-cat-1 {
		background-position: -240px 0;
	}

	&.filter-cat-2 {
		background-position: -270px 0;
	}

	&.filter-cat-3 {
		background-position: -300px 0;
	}

	&.filter-cat-4 {
		background-position: -330px 0;
	}

	&.filter-cat-1.icon-active {
		background-position: -240px -20px;
	}

	&.filter-cat-2.icon-active {
		background-position: -270px -20px;
	}

	&.filter-cat-3.icon-active {
		background-position: -300px -20px;
	}

	&.filter-cat-4.icon-active {
		background-position: -330px -20px;
	}

	&.filter-all.icon-active {
		background-position: -362px -20px;
	}

	&.car-box,
	&.car-clim,
	&.car-door,
	&.car-gazoil,
	&.car-luggage,
	&.car-people,
	&.car-radio {
		width: 20px;
		height: 20px;
	}

	&.car-gazoil {
		background-position: -300px -40px;
	}

	&.car-clim {
		background-position: -322px -40px;
	}

	&.car-box {
		background-position: -340px -40px;
	}

	&.car-door {
		background-position: -360px -40px;
	}

	&.car-people {
		background-position: -380px -40px;
	}

	&.car-radio {
		background-position: -400px -40px;
	}

	&.car-luggage {
		background-position: -420px -40px;
	}

}
