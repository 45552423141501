.blog-item {
  position: relative;
  height: 100%;
  text-align: left;
  .link {
    display: block;
    background-color: $rd-a;
    height: 100%;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
    transition: all 0.2s ease-in-out;
    .img-wp {
      display: block;
      overflow: hidden;
      img {
        transition: all 0.1s ease-in-out;
      }
    }
    .col-bottom {
      background-color: $rd-a;
      color: $wh-a;
      text-align: center;
      padding: px(15) px(15) px(18);
    }
    .title {
      font-size: px(14);
      font-weight: 700;
      line-height: 1.4em;
    }
    &:hover,
    &:focus {
      box-shadow: 0 0 15px rgba(0,0,0,0);
      .img-wp img {
        transform: scale(1.2);
      }
    }
    @include media-breakpoint-down(md) {
      .title {
        font-size: px(16);
      }
    }
    @include media-breakpoint-down(sm) {
      .title {
        font-size: px(15);
      }
    }
    @include media-breakpoint-down(xs) {
      .img-wp img {
        width: 100%;
      }
      .col-bottom {
        padding: px(20);
      }
    }
  }
}

// Blog :: Category navigation

.nav-category {
  border: 1px solid $gy-a;
  padding: px(20) px(30);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  background-color: $wh-a;
  border-radius: 8px;

  .title-cat {
    font-size: px(13);
    padding-bottom: 6px;
    color: $rd-a;
    margin-bottom: px(15);
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid shade($gy-b, 50%);
  }

  .item {
    margin-top: px(5);
  }

  .list .item:first-child {
    margin-top: 0;
  }

  .link {
    position: relative;
    font-weight: 400;
    font-size: px(14);
    color: shade($gy-b, 50%);
    display: block;
    padding-left: px(20);
  }

  .link:before {
    content: "";
    display: block;
    position: absolute;
    top: 10px;
    left: 0;
    width: 8px;
    height: 2px;
    background-color: shade($gy-b, 50%);
    transition: all 0.2s ease-in-out;
  }

  .link.active {
    color: $rd-a !important;
    font-weight: 600;
  }

  .link.active:before {
    border-color: $rd-a !important;
    background-color: $rd-a !important;
  }

  .link:focus,
  .link:hover {
    color: $rd-a;
  }

  .link:focus:before,
  .link:hover:before {
    background-color: $rd-a;
  }
  @include media-breakpoint-down(sm) {
    border: none;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
// Blog :: Article list
.category-label {
  display: inline-block;
  color: $wh-a;
  background-color: transparent;
  font-weight: 600;
  border: 1px solid $wh-a;
  letter-spacing: 0.05em;
  margin-bottom: px(20);
  font-size: px(10);
  padding: 2px 6px 2px;
  border-radius: 3px;
  @include media-breakpoint-down(sm) {
    margin-bottom: px(10);
  }
}

.item-blog {
  position: relative;
  height: 100%;
  display: block;
  padding: 0;
  overflow: hidden;
  background-color: $wh-a;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.15);
  transition: all 0.2s ease-in-out;

  .img-wp {
    position: relative;
    overflow: hidden;
  }

  .img-wp:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.15);
    transition: all 0.2s ease-in-out;
  }

  .img-wp .img-fluid {
    transition: all 0.2s ease-in-out;
  }

  .content {
    padding: 30px;
  }

  .category-blog {
    position: absolute;
    top: 17px;
    z-index: 10;
    right: 20px;
  }

  .title-blog {
    font-size: px(18);
    font-weight: 700;
    display: block;
    color: $rd-a;
    margin-bottom: px(8);
    line-height: 1.1em;
  }

  .desc {
    margin-top: px(10);
    font-size: px(14);
    margin-bottom: 0;
    line-height: 1.4em;
  }

  .btn-blog {
    color: $rd-a;
    margin-top: 10px;
    font-weight: 600;
    text-align: right;
    display: block;
  }

  &:focus .img-wp .img-fluid,
  &:hover .img-wp .img-fluid {
    transform: scale(1.1);
  }

  &:focus .img-wp:after,
  &:hover .img-wp:after {
    background-color: rgba(0,0,0,0.1);
  }
  @include media-breakpoint-down(sm) {
    .title {
      font-size: px(20);
    }
  }
}

// Pagination
.pagination {
	justify-content: center;
	margin-top: 0;

	.page-link {
		color: $rd-a;
		height: 35px;
	}

	.page-link.active {
		background-color: $rd-a !important;
		color: $wh-a !important;
		border-top-color: $rd-a;
		border-bottom-color: $rd-a;
		font-weight: 400;
	}

	.page-item.link-arrow .page-link {
		padding: px(6) px(12);
		background-color: tint($gy-a, 30%);
		color: $gy-b;
		transition: all 0.2s ease-in-out;
	}

	.page-item.link-arrow .page-link:focus,
	.page-item.link-arrow .page-link:hover {
		background-color: $rd-a;
		color: $wh-a;
	}

	.page-item .page-link:focus,
	.page-item .page-link:hover {
		background-color: tint($gy-a, 90%);
	}
}
