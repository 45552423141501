// Font family
$font-stack: 'Roboto', sans-serif;
// Html
html {
  color: $gy-b;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-stack !important;
  transition: opacity 0.1s ease-in-out;
}
// Google font ballback
// Title :: soft reset
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 1.2em;
  margin-bottom: 0;
}
p {
  line-height: 1.6em;
  margin-bottom: 0;
}
// Title hierarchy :: Bigger to smaller

.h-like-a {
  font-size: px(40);
  font-weight: 500;
  line-height: 1.3em;
  letter-spacing: 0.025em;
  line-height: 1.2em;
}

.h-like-b {
  font-size: px(30);
  font-weight: 700;
  &.bordered:after {
    content:"";
    display: block;
    margin-top: px(4);
    width: px(30);
    height: px(3);
    margin-left: px(2);
    background-color: $gy-b;
  }
}

.h-like-c {
  font-size: px(28);
  font-weight: 700;
}

.h-like-d {
  font-size: px(24);
  font-weight: 500;
}

.h-like-e {
  font-size: px(21);
  font-weight: 500;
}
@include media-breakpoint-down(lg) {
}
@include media-breakpoint-down(md) {
  .h-like-a {
    font-size: px(36);
  }
  .h-like-d {
    font-size: px(23);
  }
}
@include media-breakpoint-down(sm) {
  .h-like-a {
    font-size: px(30);
    line-height: 1.2em;
  }
  .h-like-b {
    font-size: px(27);
  }
  .h-like-c {
    font-size: px(25);
  }
  .h-like-d {
    font-size: px(21);
  }
}
@include media-breakpoint-down(xs) {
  .h-like-a {
    font-size: px(28);
    line-height: 1.1em;
  }
  .h-like-b {
    font-size: px(23);
  }
  .h-like-c {
    font-size: px(22);
  }
  .h-like-d {
    font-size: px(20);
  }

  .h-like-e {
    font-size: px(19);
  }

}
// Paragraph hierarchy :: Bigger to smaller

@include media-breakpoint-down(lg) {
  .p-like-a {}
}
@include media-breakpoint-down(md) {
  .p-like-a {}
}
@include media-breakpoint-down(sm) {
}
@include media-breakpoint-down(xs) {
}
