// Modal Spinner :: Structure
.modal-header {
	padding: 15px 50px 15px 25px;
	position: relative;
	.close {
		position: absolute;
		top: 50%;
		right: 20px;
		font-size: 30px;
		padding: 0 !important;
		margin: 0 !important;
		margin-top: -15px !important;
	}
}
.modal-body {
	padding: 15px 25px;
}
.modal-title {
	font-size: px(28);
	font-weight: 500;
	line-height: 1.1em;
	@include media-breakpoint-down(xs) {
		font-size: px(24);
	}
}
.spinner-wp {
	display: block;
	width: 32px;
	height: 32px;
	transition: all 0.2s ease-in-out;
}

.spinner-circle {
	border: 4px rgba(#FFF, 1) solid;
	border-top: 4px $bk-a solid;
	border-radius: 50%;
	animation: spCircRot 0.6s infinite linear;
}
@keyframes spCircRot {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
// Spinner :: Structure
.spinner-wp {
	display: block;
	width: 32px;
	height: 32px;
	transition: all 0.2s ease-in-out;
}
// Modal Spinner :: Open
#modal-loader {
	display: none;
	@include position(fixed, 50% null null 50%);
	z-index: 99999;
	width: px(60);
	height: px(60);
	padding: px(12) 0 0 px(11);
	margin: px(-30) 0 0 px(-30);
	background-color: $bk-a;
	border-radius: px(60);
	text-align: center;
}

body.modal-loading:before {
	content: "";
	@include position(fixed, 0 null null 0);
	z-index: 9999;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.5);
}

body.modal-loading #modal-loader {
	display: block;
}
