.item-faq {
  display: block;
  width: 100%;
  position: relative;
  text-align: left;
  line-height: 1.2em;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  background-color: $wh-a;

  .button {
    color: $gray-800;
    width: 100%;
    line-height: 1.3em;
    text-align: left;
    font-size: px(20);
    display: block;
    font-weight: 500;
    padding: 18px 60px 20px 25px;
    position: relative;
  }

  .button .material-icons {
    position: absolute;
    top: 17px;
    right: 15px;
    color: $rd-a;
  }

  .content {
    padding: 0 25px 20px;
    color: $gy-b;
    margin-top: -8px;
    transition: all 0.2s ease-in-out;
  }

  .content a {
    text-decoration: underline;
    color: $rd-a;
  }

  .content p {
    font-size: px(15);
    line-height: 1.4em;
    margin-bottom: 0;
  }

  &.open {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  }

  &.open .button .material-icons {
    transform: rotate(-180deg);
  }

  &.open .content {
    border-color: $wh-a;
  }
  @include media-breakpoint-down(lg) {
    .button {
      font-size: px(18);
    }
  }

  @include media-breakpoint-down(sm) {
    .button {
      font-size: px(16);
      padding: 15px 60px 17px 25px;
    }

    .content p {
      font-size: px(12);
    }

    .button .material-icons {
      top: 14px;
      font-size: px(20);
    }
  }
  @include media-breakpoint-down(xs) {
    .button {
      font-size: px(15);
      padding: 15px 50px 17px 20px;
    }

    .content p {
      font-size: px(11.5);
    }

    .content {
      padding: 0 20px 20px;
    }
  }
}
