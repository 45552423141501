// Reservation
#resa-home-wp {
  background-image: url("../images/illustrations/bg-resa.jpg");
  background-size: cover;
  background-position: top center;
  height: px(400);

  &.except-resa {
    .row-except {
      background: transparent !important;
      > * {
        position: relative;
        z-index: 10;
      }
    }
    .row-except:after {
      content:"";
      display: block;
      height: 65px;
      position: absolute;
      width: 300%;
      left: -150%;
      bottom: -15px;
      background-color: rgba(255,255,255,0.1);
    }

  }

  #resa-home-collapse:not(.nohiding) {
    display: none;
  }
  &.opened {
    #resa-home-collapse {
      display: block;
    }
    .btn-b {
      position: absolute;
      width: px(165);
      height: px(50);
      top: px(15);
      right: px(15);
    }
    @include media-breakpoint-down(lg) {
      .btn-b {
        width: px(175);
      }
    }
    @include media-breakpoint-down(md) {
      .btn-b {
        width: px(140);
      }
    }
    @include media-breakpoint-down(sm) {
      .btn-b {
        margin-top: px(10);
        position: relative;
        height: auto;
        top: auto;
        right: auto;
        width: 100% !important;
      }
    }
  }

  .container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 px(100);
    flex-flow: column nowrap;
  }

  .h-like-a {
    color: $wh-a;
    text-align: center;
    text-shadow: 1px 0 10px rgba(0,0,0,0.4);
  }

  .form-wp {
    width: 100%;
    background-color: $gy-c;
    border-radius: 5px;
    color: $wh-a;
    padding: px(15);
  }

  .form-control {
    box-shadow: none;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: $wh-a;
    color: $gy-b;
    border-radius: px(3);
    border: none;
    font-weight: 500;
    font-size: px(12);
    padding: px(13) px(10) 0 px(9);
    height: px(36);

    &:focus {
      border-color: $bk-a;
      box-shadow: none;
      color: $bk-a;
      background: $wh-a;
    }
  }

  .datepicker-wp {
    position: relative;

    .form-control {
      padding-right: px(20);
    }

    .ui-datepicker-trigger {
      position: absolute;
      top: 50%;
      right: px(5);
      margin-top: px(-5);

    }
  }

  .datepicker-wp,
  .selectpicker-wp {
    position: relative;

    .title {
      position: relative;
      z-index: 2;
      cursor: pointer;
      font-size: px(8.5);
      color: $bk-a;
      font-weight: 400;
      top: px(4);
      position: absolute;
      left: px(8);
      z-index: 2;
      text-transform: uppercase;
    }
  }

  .selectpicker-wp {
    position: relative;

    .bootstrap-select {
      width: 100% !important;
    }

    .filter-option {
      @include ellipsis;
    }

    .dropdown-toggle {
      display: flex;
      align-items: center;
      width: 100%;
      padding-right: px(20) !important;
      @extend .form-control;
      outline: none;
    }

    .dropdown-toggle:focus {
      outline: none !important;
    }

    .dropdown-toggle:after {
      position: absolute;
      top: 50%;
      right: px(5);
      margin-top: -3px;
      border: none !important;
      @extend .sprite;
      @extend .arrow-dropdown-black;
    }

    .bootstrap-select.show .dropdown-toggle:after {
      transform: rotate(-180deg);
    }

    &.except-contact {
      .dropdown-toggle {
        padding: px(8) 0 px(6);
        border: none;
        border-bottom: 1px solid $gy-b;
        height: px(35);
        color: shade($gy-b, 25%) !important;
        font-style: italic;
        opacity: 1;
        font-size: px(14);
        font-weight: 400;
      }

    }
  }

  .btn-b {
    width: 100%;
  }

  .row-except {
    background-color: rgba(255,255,255,0.1);
    margin-top: px(15);
    position: relative;
    &:after {
      content:"";
      position: absolute;
      bottom: px(-15);
      left: 0;
      width: 100%;
      height: px(15);
      border-radius: 0 0 5px 5px;
      background-color: rgba(255,255,255,0.1);
    }
    @include media-breakpoint-down(sm) {
      &:after {
        border-radius: 0;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .container {
      padding: 0 px(50);
    }
  }
  @include media-breakpoint-down(md) {
    height: px(400);
    .container {
      padding: 0 px(15);
    }
  }
  @include media-breakpoint-down(sm) {
    height: px(440);
  }
  @include media-breakpoint-down(xs) {
    padding: px(30) 0;
    display: block;
    height: auto;
  }
  &.except-page {
    height: auto;
    padding: px(50) 0;
  }
}

// Presentation
#presentation-wp {
  position: relative;
  &:before {
    content:"";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 66.66%;
    background-image: url(../images/illustrations/bg-presentation-left.jpg);
    background-size: cover;
    background-position: left bottom;
  }
  &:after {
    content:"";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 33.33%;
    background-image: url(../images/illustrations/bg-presentation-right.jpg);
    background-size: cover;
    background-position: top center;
  }
  .container {
    position: relative;
  }
  @include media-breakpoint-down(sm) {
    padding-top: px(400);
    &:before {
      width: 100%;
    }
    &:after {
      height: px(400);
      width: 100%;
      background-position: left top;
    }
    @include media-breakpoint-down(xs) {
      padding-top: 0;
      &:after {
        display: none;
      }
    }
  }

}

// News higlighted
#news-higlight-wp {
  .item-large {
    display: block;
    position: relative;
    background-size: cover;
    background-position: center;
    .caption {
      padding: px(0) px(65) px(20) px(20);
      color: $wh-a;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%,  rgba(0,0,0,0.2) 18%, rgba(0,0,0,0.9) 100%);
      background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 18%,rgba(0,0,0,0.9) 100%);
      background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 18%,rgba(0,0,0,0.9) 100%);
      .arrow-wp {
        background-color: $rd-a;
        text-align: center;
        position: absolute;
        bottom: 0;
        right: 0;
        width: px(55);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;
      }
      .title {
        font-size: px(22);
        font-weight: 500;
        text-transform: uppercase;
      }
      .desc {
        line-height: 1.3em;
        font-size: px(13);
        margin-top: px(5);
      }
    }
    &:hover,
    &:focus {
      .arrow-wp {
        background-color: shade($rd-a, 10%);
      }
    }
  }
  .item-small {
    display: block;
    position: relative;
    height: 100%;
    padding-bottom: px(40);
    .title {
      font-size: px(18);
      font-weight: 500;
      color: $gy-b;
      height: px(65);
      padding-top: px(10);
    }
    .desc {
      font-size: px(13);
      line-height: 1.3em;
    }
    .btn-wp {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      text-align: right;
    }
    &:hover,
    &:focus {
      .btn-a {
        background-color: shade($rd-a, 10%);
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .equal-col-item {
      min-height: auto !important;
    }
    .item-large {
      height: px(300);
    }
    .item-small {
      .title {
        height: auto;
        margin-bottom: px(10);
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .item-large {
      height: px(225);
      .caption {
        padding: px(15);
        .arrow-wp {
          display: none;
        }
        .title {
          font-size: px(20);
        }
      }
    }
  }
}
