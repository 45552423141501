#header-top {
	background-color: $gy-b;
	color: $wh-a;
	padding: px(5) 0 px(4);
	position: relative;
	z-index: 3;

	#logout-btn {
		display: none;
	}

	&.logout {
		padding-right: 25px !important;

		#logout-btn {
			display: block;
			background-color: $wh-a;
			color: $rd-a;
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			width: px(25);
			text-align: center;
			padding-top: px(5);

			.material-icons {
				font-size: px(18);
			}
		}
	}

	.list {
		text-align: right;

		.item {
			position: relative;
			display: inline-block;
			vertical-align: top;
			font-size: px(12);

			&:not(:last-child) {
				margin-right: px(20);
			}

			&:last-child {
				margin-right: px(10);
			}

			.link,
			.title {
				color: $wh-a;
				text-transform: uppercase;
			}

			&.except-dropdown {
				position: relative;
				padding-right: px(15);

				.txt {
					text-transform: uppercase;
				}

				.selected:after {
					content: "";
					display: block;
					position: absolute;
					right: px(-20);
					top: px(4);
					@extend .sprite;
					@extend .arrow-dropdown-white;
				}

				.dropdown-wp.open {
					.selected:after {
						transform: rotate(180deg);
					}
				}

				.dropdown-list {
					width: px(250) !important;
					padding: 0 0 px(5) 0 !important;

					.dropdown-item {
						padding: px(0) px(15) !important;

						.txt {
							text-transform: none;
						}

						&:before {
							content: "- ";
							color: $wh-a;
						}
					}
				}
			}

			.title {
				margin-right: px(5);
			}

			.dropdown-wp {
				position: relative;
				display: inline-block;
				vertical-align: top;
				color: $wh-a;

				.txt {
					color: $wh-a;
					position: relative;
					display: inline-block;
					vertical-align: top;
					top: px(0);
				}

				.selected {}

				.dropdown-list {
					position: absolute;
					top: px(25);
					left: px(-10);
					z-index: 1;
					width: px(65);
					background-color: $gy-b;
					text-align: left;
					padding: px(7.5) 0;
					display: none;

					.dropdown-item {
						display: block;
						padding: px(0) px(10);
						background-color: transparent !important;
					}
				}
			}
		}
	}
	@include media-breakpoint-down(sm) {
		padding: px(5) 0 px(2);

		.list {
			.item {
				font-size: px(10.5);

				&:not(:last-child) {
					margin-right: px(10);
				}

				.title {
					margin-right: px(5);
				}

				.dropdown-wp {
					.selected {
						padding-top: px(0);

						&:after {
							top: px(2);
						}
					}

					.dropdown-list {
						top: px(23);
					}
				}
			}
		}
	}
	@include media-breakpoint-down(xs) {
		.list .item .dropdown-wp#trigger-resa .dropdown-list {
			width: px(200) !important;
		}
		.list .item .dropdown-wp#trigger-resa .dropdown-list .dropdown-item:first-child {
			margin-bottom: px(5);
		}
		.list .item .dropdown-wp#trigger-resa .dropdown-list .dropdown-item {
			padding-left: px(14) !important;
		}
		.list .item .dropdown-wp#trigger-resa .dropdown-list .dropdown-item .txt {
			text-transform: uppercase;
		}
		.list {
			padding: px(0) 0;

			.item .dropdown-wp#trigger-lang .txt {
				display: none;
			}
		}
	}
}

#header-main {
	position: relative;
	z-index: 2;
	padding: 0 px(260) 0 px(290);
	height: px(70);
	#nav-mobile-trigger {
		display: none;
	}
	.link-logo {
		position: absolute;
		top: px(5);
		left: px(5);
		display: block;
	}

	.link-account {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: px(70);
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $rd-a;
		text-align: center;
	}

	.link-phone {
		position: absolute;
		top: px(20);
		right: px(85);
		display: inline-block;
		color: $rd-a;
		font-weight: 500;
		font-size: px(18);
		vertical-align: middle;
		padding-left: px(40);

		&:before {
			content: "";
			@extend .sprite;
			@extend .icon-phone;
			position: absolute;
			top: px(-2);
			left: 0;
		}
	}

	#nav-main {
		text-align: center;
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: space-between;
		max-width: px(750);
		margin: 0 auto;

		.item {
			height: 100%;
			justify-content: center;
			display: inline-block;
			position: relative;

			.link {
				height: 100%;
				position: relative;
				display: block;
				display: flex;
				align-items: center;
				color: $bk-a;
				line-height: 1.2em;
				text-transform: uppercase;
				font-size: px(15.5);
				transition: all 0.2s ease-in-out;

				&:after {
					content: "";
					width: 100%;
					position: absolute;
					bottom: 0;
					left: 0;
					height: px(5);
					background-color: $rd-a;
					opacity: 0;
				}
			}

			.link.active,
			.link:focus,
			.link:hover {
				color: $rd-a;
			}

			.link.active:after {
				opacity: 1;
			}
		}
		.nav-sub {
			.item-sub {
				&:not(:last-child) {
					margin-bottom: px(7.5);
				}
			}

			.link-sub {
				font-size: px(13);
				display: block;
				line-height: 1.3em;
				padding-left: px(10);
				position: relative;

				&:before {
					content: "-";
					font-weight: 700;
					position: absolute;
					top: px(0);
					left: 0;
				}
			}

			.link-sub:focus,
			.link-sub:hover {}

			.link-sub.active {}
		}
		@include media-breakpoint-down(md) {
			.nav-sub {
				.link-sub {
					font-size: px(12);
				}
			}
		}
		@include media-breakpoint-down(sm) {
			.nav-sub {
				background-color: shade($gy-a,1%);
				top: px(38);
				border-top: 1px solid shade($gy-a,4%);
				width: 100%;
				padding: px(5) px(30) px(7.5);
				text-align: center;

				.item-sub {
					display: inline-block;
					line-height: 1em;
					margin-bottom: 0 !important;
				}

				.link-sub {
					line-height: 1em;
					display: block;

					&:before {
						display: none;
					}
				}
			}
		}
		@include media-breakpoint-down(xs) {
			.nav-sub {
				top: auto;
				margin-right: 1px;
				border-bottom: 1px solid shade($gy-a,4%);
				padding: px(5) px(20) px(15);
				text-align: left;

				.item-sub {
					display: block;
					margin: px(10) 0;

					.link-sub {
						font-weight: 700;
						color: $gy-c;
						font-size: px(11);
						text-transform: uppercase;
					}
				}
			}
		}
	}
	.nav-sub {
		display: none;
		position: relative;
		top: auto;
		left: auto;
		height: auto;
		opacity: 1;
		background-color: $wh-a;
	}
	div[data-navsub] {
		position: relative;
	}
	.focus .nav-sub{
		display: block;
	}
	@include media-breakpoint-up(sm) {
		div[data-navsub].focus > .nav-sub {
			visibility: visible;
			display: block;
			top: auto;
			padding: px(10) px(15);
			height: auto;
			opacity: 1;
		}

		.nav-sub {
			display: none;
			position: absolute;
			left: -15px;
			z-index: 99;
			/*height: 0;
			width: px(180);
			padding: 0;
			opacity: 0;*/
			transition: all 0.1 ease-in-out;
			background-color: rgba(255,255,255,0.8);
			box-shadow: 0 3px 5px -2px rgba(0,0,0,0.2);

			&:after {
				content: "";
				position: absolute;
				top: px(-5);
				left: px(20);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 5px 5px 5px;
				border-color: transparent transparent rgba(255,255,255,0.8) transparent;
			}
		}
	}

	.menu-open {
		#nav-main {
			display: flex !important;
			@include media-breakpoint-down(xs) {
				display: block !important;
			}
		}
	}
	@include media-breakpoint-up(md) {
		.nav-sub {
			top: auto;
			background-color: $wh-a;
		}
	}
	@media (max-width:1300px) {
		padding: 0 px(260) 0 px(240);

		#nav-main {
			max-width: px(700);

			.item .link {
				font-size: px(14.5);
			}
		}

		.link-logo {
			width: px(225);
			top: px(11);
		}
		@include media-breakpoint-down(lg) {
			padding: 0 px(95) 0 px(230);

			.link-logo {
				width: px(200);
				top: px(14);
			}

			.link-phone {
				display: none;
			}

			#nav-main {
				max-width: px(680);

				.item {
					.link {
						font-size: px(14);
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			margin-bottom: px(50);
			.link-phone {
				display: block;
			}

			.link-logo {
				width: auto;
				top: px(5);
			}

			#nav-main {
				max-width: 100%;
				width: 100%;
				position: absolute;
				left: 0;
				top: px(70);
				background-color: tint($gy-a, 20%);
				height: px(50);
				padding: 0 px(30);
				.item {
					.link {
						font-size: px(13);
					}
				}
			}
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: px(40);
			#nav-main {
				padding: px(5) px(15);
				height: px(40);

				.item .link {
					font-size: px(12);
				}
				.link.active:after {
					bottom: px(-5);
				}
			}
		}
	}
	@include media-breakpoint-down(xs) {
		text-align: center;
		height: px(56);
		padding: 0;
		margin-bottom: px(0);
		#nav-mobile-trigger {
			position: absolute;
			top: 0;
			right: 0;
			display: block;
			background-color: $rd-a;
			width: px(50);
			height: px(56);
		}
		.link-logo {
			width: px(70);
			top: 0;
			left: px(5);
		}
		.link-phone {
			text-align: center;
			font-size: px(14);
			right: px(62);
			padding: 0;
			top: px(17);
			&:before {
				transform: scale(0.7) translate(-45px, -5px);
			}
		}
		.link-account {
			display: none;
		}
		#nav-main {
			display: none;
			height: auto;
			padding: px(5) px(15);
			top: px(56);

			.item {
				display: block;
				width: 100%;

				.link {
					padding: px(6) 0;
					font-size: px(13.5);
					font-weight: 500;
				}

				.link:after {
					display: none;
				}
			}
		}
	}
}
// Header :: Fixed or small/large

body {
	padding-top: 0;
}

.anchor-scroll {
	display: block;
	margin-top: 0;
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: transparent;
}
@include media-breakpoint-down(lg) {
	body {
		padding-top: 0;
	}

	.anchor-scroll {
		margin-top: 0;
	}
}
// Header :: Small on scroll

body.header-small #header {}
@include media-breakpoint-up(lg) {
	body.header-small #header {}
}
// Navigation :: Force Display on large device
@include media-breakpoint-up(md) {
	#nav-main {
		display: flex !important;
	}

	#nav-main-wp {
		display: block !important;
	}

	#nav-mobile-trigger {
		display: none;
	}
}

// Header page
#header-page {
	background-color: $rd-a;
	color: $wh-a;
	text-align: center;
	padding: px(35) 0;
	box-shadow: 0px 0px 5px -1px inset rgba(0, 0, 0, 0.5);
	.title {
		font-size: px(36);
		font-weight: 400;
	}
	@include media-breakpoint-down(lg) {
		.title {
			font-size: px(32);
		}
	}
	@include media-breakpoint-down(md) {
		padding: px(30) 0;
		.title {
			font-size: px(29);
		}
	}
	@include media-breakpoint-down(sm) {
		padding: px(25) 0;
		.title {
			font-size: px(26);
		}
	}
	@include media-breakpoint-down(xs) {
		padding: px(15) 0;
		.title {
			font-size: px(22);
		}
	}
}
