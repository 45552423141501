// Reset :: Button & Links

button {
	background-color: transparent;
	border: none;
	padding: 0;
	outline: none !important;
	cursor: pointer;
}

a,
a:focus,
a:hover {
	text-decoration: none;
	cursor: pointer;
	color: inherit;
}
// Hover :: Link

.td-und-hover-a:focus,
.td-und-hover-a:hover {
	text-decoration: underline;
}

.td-und-hover-b:focus > *,
.td-und-hover-b:hover > * {
	text-decoration: underline;
}
// Buttons :: List

.btn-a {
	font-size: px(11);
	display: inline-block;
	font-weight: 400;
	padding: px(6) px(12);
	text-transform: uppercase;
	text-align: center;
	transition: all 0.2s ease-in-out;
}

.btn-b {
	font-size: px(13);
	border-radius: 4px;
	display: inline-block;
	font-weight: 400;
	text-align: center;
	padding: px(9) px(15);
	transition: background 0.2s ease-in-out;
	&.large {
		padding: px(12) px(20);
		font-size: px(16);
	}
}

.btn-a,
.btn-b {
	&.red {
		background-color: $rd-a;
		color: $wh-a;
		&:focus,
		&:hover {
			background-color: shade($rd-a, 10%);
		}
	}
	&.gray {
		background-color: $gray-800;
		color: $wh-a;
		&:focus,
		&:hover {
			background-color: shade($gray-800, 10%);
		}
	}
	&.white {
		background-color: $wh-a;
		color: $rd-a;
		&:focus,
		&:hover {
			color: $wh-a;
			background-color: shade($rd-a, 30%);
		}
	}
}
// Button :: Hamburger

.btn-mobile-hamburger {
	display: block;
	margin: 0 auto;
	width: 30px;
	height: 20px;
	position: relative;
	z-index: 2;
	transform: rotate(0deg);
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	span {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background: $wh-a;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: all 0.2s ease-in-out;
	}

	span:nth-child(1) {
		top: 0;
	}

	span:nth-child(2),
	span:nth-child(3) {
		top: 9px;
	}

	span:nth-child(4) {
		top: 18px;
	}
}
// Button :: Hamburger Open

.menu-open .btn-mobile-hamburger {
	span:nth-child(1) {
		top: 18px;
		width: 0;
		left: 50%;
	}

	span:nth-child(2) {
		transform: rotate(45deg);
	}

	span:nth-child(3) {
		transform: rotate(-45deg);
	}

	span:nth-child(4) {
		top: 18px;
		width: 0;
		left: 50%;
	}
}
// Button :: Top

#btn-back-top {
	@include position(fixed, null 30px 30px null);
	z-index: 99;
	transition: all 0.2s ease-in-out;

	&:focus,
	&:hover {}
	@include media-breakpoint-down(sm) {
		right: 15px;
		bottom: 15px;
	}
}
