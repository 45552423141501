// Body

body {
	overflow: hidden;
	overflow-y: auto;
}

body,
html {
	width: 100%;
	background-color: #fff;
}

html {
	overflow-x: hidden;
}
// Utils :: Image renderer optimize

.renderer-optmimize {
	image-rendering: -moz-crisp-edges;
	image-rendering: -moz-crisp-edges;
	image-rendering: -o-crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	-ms-interpolation-mode: nearest-neighbor;
}
// Utils :: Hide play button on video iphone

*::-webkit-media-controls-start-playback-button {
	display: none !important;
	-webkit-appearance: none;
}
// Components :: Magnific popup

.mfp-close {
	width: 30px !important;
	height: 30px;
	line-height: 30px;
	position: absolute;
	right: 0;
	top: 5px;
	text-decoration: none;
	text-align: center;
	opacity: 0.65;
	filter: alpha(opacity=65);
	padding: 0 0 18px 10px;
	color: #FFF;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
	display: block;
	background-image: url("../images/icons/sprite-popup.png") !important;
	background-position: -60px 0 !important;
	text-indent: 999em;
	overflow: hidden!important;
	transition: all 0.2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow {
	position: absolute;
	opacity: 0.65;
	filter: alpha(opacity=65);
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 30px;
	height: 60px;
	display: block;
	background-image: url("../images/icons/sprite-popup.png") !important;
	transition: all 0.2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow-left {
	left: 20px;
	background-position: 0 0 !important;
}

.mfp-arrow-right {
	right: 20px;
	background-position: -30px 0 !important;
}

.mfp-arrow:after,
.mfp-arrow:before {
	display: none !important;
}

.mfp-zoom-out .mfp-with-anim {
	opacity: 0;
	transform: scale(1.3);
	transition: all 0.2s ease-in-out;
}

.mfp-zoom-out.mfp-bg {
	opacity: 0;
	transition: all 0.2s ease-in-out;
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
	opacity: 1;
	transform: scale(1);
}

.mfp-zoom-out.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
	transform: scale(1.3);
	opacity: 0;
}

.mfp-zoom-out.mfp-removing.mfp-bg {
	opacity: 0;
}
// Components :: Slick

.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	margin-top: px(15);
	text-align: center;

	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 2px;
	}

	button {
		width: 14px;
		height: 14px;
		margin-right: 5px;
		background-color: $bk-a;
		border-radius: px(18);
		transition: all 0.2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active button {
		background-color: $bk-a;
	}

	button:focus,
	button:hover {
		background-color: $bk-a;
	}
}

@include media-breakpoint-up(sm) {
	.tooltip {
		display: none !important;
	}
}


.popover-header {
	font-weight: 700;
	background-color: #efefef;
	text-transform: uppercase;
}
