// Reservation :: Header step

#resa-header {
  position: relative;
  z-index: 10;
  width: 100%;
  background-color: $wh-a;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  padding: px(10) 0;

  .logo {
    position: relative;
    display: block;
    padding: 0;
  }

  .logo .img-fluid {
    display: block;
    margin: 0 auto;
  }

  .logo:after {
    content: "";
    position: absolute;
    top: -10px;
    right: -30px;
    height: calc(100% + 20px);
    width: 1px;
    background-color: tint($gy-b, 75%);
  }

  .item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: tint($gy-b, 50%);
  }

  .item .txt {
    font-size: px(15);
    font-weight: 400;
  }

  .item .number {
    font-size: px(18);
    line-height: 1em;
    position: relative;
    top: -1px;
    font-weight: 400;
    margin-right: 5px;
  }

  .item .number:after {
    content: ".";
  }

  .item:not(.first):before {
    content: "";
    position: absolute;
    top: 50%;
    left: -15px;
    height: 14px;
    margin-top: -7px;
    width: 1px;
    background-color: tint($gy-b, 75%);
  }

  .item.active {
    color: $rd-a;
  }

  .item.active .number:after {
    display: none;
  }

  .item.active .number {
    background-color: $rd-a;
    margin-right: 10px;
    font-size: px(14);
    padding-top: 5px;
    color: $wh-a;
    text-align: center;
    border-radius: 25px;
    width: 25px;
    height: 25px;
  }

  .item.active .txt {
    font-weight: 700;
  }
  @include media-breakpoint-down(md) {
    padding: px(5) 0;

    .logo {
      img {
        height: px(55);
        margin: 0 !important;
      }
    }

    .logo:after {
      right: 5px;
    }

    .item .txt {
      font-size: px(15);
    }

    .item .number {
      font-size: px(16);
    }

    .item.active .number {
      font-size: px(13);
      width: 20px;
      height: 20px;
      padding-top: 3px;
    }
  }
  @include media-breakpoint-down(sm) {
    .logo:after {
      right: 5px;
    }

    .row {
      align-items: flex-start !important;
    }

    .logo .img-fluid {
      display: block;
      width: auto;
      height: 50px;
      margin: 0 auto;
    }

    .logo:after {
      right: -8px;
    }

    .item {
      flex-flow: column wrap;
      padding-top: 8px;
    }

    .item .number {
      margin-right: 0 !important;
    }

    .item .number:after {
      display: none;
    }

    .item .txt {
      font-size: px(13);
    }

    .item.active .number {
      font-size: px(11.5);
      width: 17px;
      height: 17px;
      padding-top: 2px;
    }

    .item.active .txt {
      margin-top: 3px;
    }

    .item:not(.active) .txt {
      padding-top: 3px;
    }
  }
  @include media-breakpoint-down(xs) {
    padding: px(2) 0;

    .col-4 {
      flex: 0 0 85px;
      max-width: 85px;
    }

    .col-8 {
      flex: 0 0 calc(100% - 85px);
      max-width: calc(100% - 85px);
    }

    .row {
      align-items: center !important;
    }

    .item {
      padding-top: 0;
    }

    .item .txt {
      display: none;
    }

    .item.active .number {
      width: 20px;
      height: 20px;
      padding-top: 4px;
      font-size: px(12);
    }
  }
}
// Reservation :: Summary

#resa-summary-wp {
  overflow: hidden;
  position: relative;
  padding: 30px 0;
  background-image: url("../images/illustrations/bg-resa.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  @include media-breakpoint-down(md) {
    padding: 30px 0;
    background-image: url("../images/illustrations/bg-resa.jpg");
  }
  @include media-breakpoint-down(xs) {
    padding: 15px 0 15px 0 !important;
  }
}

#resa-summary {
  position: relative;
  display: table;
  width: 100%;
  margin: 0 auto;
  background-color: $wh-a;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);

  .column {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    width: 41%;
    padding: 20px 15px 20px 0;

    &:not(.except):after {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: tint($gy-b, 75%);
      right: px(20);
      top: 0;
    }
  }

  .column.except {
    width: 18%;
  }

  .column.except .list {
    border-right: none;
  }

  .column:first-child .list {
    padding-left: 50px;
  }

  .column:first-child .list:after {
    left: 20px;
  }

  .list {
    padding: 0 15px 0 40px;
    position: relative;
    height: 100%;
  }

  .column .material-icons {
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -10px;
    color: $gy-b;
  }

  .column.except-bis .material-icons {
    left: 5px;
    color: $gy-b;
  }

  .column.except .list:after {
    display: none;
  }

  .column.except .list {
    padding-left: 40px;
  }

  .column.except .img {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 5px;
    font-size: px(18);
  }

  .place {
    text-transform: uppercase;
    display: block;
    line-height: 1.2em;
    font-weight: 600;
    color: $rd-a;
    font-size: px(14);
  }

  .date {
    color: $gy-c;
    font-weight: 400;
    font-size: px(12);
    display: block;
    line-height: 1.2em;
    margin-top: 5px;
  }

  .btn-submit {
    width: 130px;
    height: 84px;
    background-color: $rd-a;
    color: $wh-a;

    &.open {
      .material-icons {
        transform: rotate(180deg);
      }
    }
  }

  .btn-submit > * {
    display: inline-block;
    vertical-align: middle;
  }
  @include media-breakpoint-down(lg) {
    .column:not(.except):after {
      right: 15px;
    }
  }
  @include media-breakpoint-down(md) {
    .column {
      padding: 15px 15px 15px 0;
    }

    .column:not(.except):after {
      right: 5px;
    }

    .btn-submit {
      height: 81px;
      width: 120px;
    }
  }
  @include media-breakpoint-down(sm) {
    .place {
      font-size: px(12);
    }

    .date {
      font-size: px(11);
      margin-top: px(2);
    }

    .column {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .column.except .img {
      display: none !important;
    }

    .column .list {
      padding-left: 10px !important;
    }

    .column .material-icons {
      display: none !important;
    }

    .btn-submit {
      height: 70px;
      font-size: px(13);
      width: 90px;

      .material-icons {
        font-size: px(18);
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .column.except .img {
      left: 8px !important;
      font-size: px(15) !important;
      display: block !important;
    }

    .column:after {
      display: none !important;
    }

    .column .material-icons {
      left: 7px !important;
      font-size: px(20) !important;
      display: block !important;
    }

    .column,
    .column .list {
      display: block;
      width: 100% !important;
    }

    .column .list {
      border-right: none !important;
      padding-left: 32px !important;
    }

    .column {
      border-bottom: 1px solid tint($gy-b, 75%);
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .column.except {
      border-bottom: none !important;
    }

    .list:after {
      left: 10px !important;
    }

    .column.except .list:after {
      left: 10px !important;
    }

    .btn-submit {
      height: 35px;
      width: 100%;
    }
  }
}
// Reservation :: Modify

#resa-home-wp.except-resa {
  background-color: $gy-c;
  height: auto !important;
  background-image: none !important;

  &.opened {
    .btn-b {
      width: px(185);
    }
    @include media-breakpoint-down(lg) {
      .btn-b {
        width: px(195);
      }
    }
    @include media-breakpoint-down(md) {
      .btn-b {
        width: px(145);
      }
    }
    @include media-breakpoint-down(sm) {
      width: 100% !important;
    }
  }

  .container {
    padding: px(0) px(15) !important;
  }

  .container .form-wp {
    max-width: 1030px;
    margin: 0 auto;
  }
  @include media-breakpoint-down(lg) {
    .container {
      padding: px(15) px(15) !important;
    }

    .container .form-wp {
      padding: 0;
    }
  }
  @include media-breakpoint-down(xs) {
    padding: px(15) 0;

    .container {
      padding: px(0) px(15) !important;
    }
  }
}
// Reservation :: Select category

.selectpicker-wp.select-category {
  width: 220px;
  display: inline-block;

  .bootstrap-select {
    width: 220px !important;
  }

  .bootstrap-select.show .dropdown-toggle:after {
    transform: rotate(180deg);
  }

  .dropdown-toggle {
    display: block;
    padding: 8px 15px;
    border-radius: 5px;
    outline: none !important;
  }

  .dropdown-toggle {
    height: px(35);
    background-color: $rd-a !important;
  }

  .filter-option-inner-inner {
    font-size: px(13) !important;
  }

  .filter-option-inner-inner {
    color: $wh-a !important;
    font-size: px(14);
  }

  .dropdown-toggle:after {
    @extend .sprite;
    @extend .arrow-dropdown-white;
    position: absolute;
    top: 50%;
    right: px(9);
    margin-top: -5px;
    border: none;
  }

  .dropdown-menu li a {
    padding-left: 40px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .dropdown-menu li a:before {
    content: "";
    @extend .sprite;
    position: absolute;
    top: 50%;
    left: 5px;
    margin-top: -9px;
  }

  .dropdown-menu li a.filter-all:before {
    @extend .filter-all;
  }

  .dropdown-menu li.active a.filter-all:before {
    @extend .icon-active;
  }

  .dropdown-menu li a.category-1:before {
    @extend .filter-cat-1;
  }

  .dropdown-menu li a.category-2:before {
    @extend .filter-cat-2;
  }

  .dropdown-menu li a.category-3:before {
    @extend .filter-cat-3;
  }

  .dropdown-menu li a.category-4:before {
    @extend .filter-cat-4;
  }

  .dropdown-menu li a:active {
    background-color: #f8f9fa !important;
    color: $bk-a !important;
  }

  .dropdown-menu li.active a.category-1:before,
  .dropdown-menu li.active a.category-2:before,
  .dropdown-menu li.active a.category-3:before,
  .dropdown-menu li.active a.category-4:before {
    @extend .icon-active;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
  }
  @include media-breakpoint-down(sm) {
    .bootstrap-select {
      width: 100% !important;
    }
  }
}
// Reservation :: Step 1

.resa-step-1 {
  position: relative;
  height: 100%;

  &.unavailable .btn-wp:after,
  &.unavailable:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.5);
    z-index: 4;
  }

  &.unavailable .col-bottom {
    border-top: none;
  }

  .content-item {
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    background-color: $wh-a;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    border-radius: 8px;
    overflow: hidden;
  }

  .col-top {
    background-color: $wh-a;
  }

  .img-wp {
    padding: 15px 20px 0;
    border-bottom: 1px solid shade($gy-a, 10%);
    position: relative;

    .btn-popup {
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: $gy-a;
      width: px(30);
      height: px(30);
      text-align: center;
      padding-top: px(4);

      .material-icons {
        font-size: px(20);
      }
    }
  }

  .category {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: px(10);
    font-weight: 600;
    text-transform: uppercase;
    color: $gy-c;
  }

  .discount {
    position: absolute;
    top: 18px;
    right: 25px;
    color: $wh-a;
    background-color: $rd-a;
    font-weight: 400;
    letter-spacing: 0.05em;
    font-size: px(10);
    padding: 2px 6px;
    border-radius: 3px;

    &.except-moins {
      right: 70px;
    }

    html[lang=en_US] &.except-moins {
      right: 82px;
    }
  }

  .desc {
    font-size: px(11);
  }

  .content-txt {
    padding: 20px;
  }

  .title-car {
    font-size: px(15);
    color: $gy-b;
    line-height: 1.3em;
    font-weight: 600;
    letter-spacing: 0.025em;
    margin-bottom: 10px;
  }

  .title-car em {
    font-size: 85%;
    font-weight: 400;
  }

  .car-icon {
    display: flex;
    align-items: center;
    font-size: px(11);
    color: $gy-b;
    margin-top: 2px;
  }

  .car-icon .txt {
    white-space: nowrap;
    padding-left: px(3);
  }

  .col-bottom {
    background-color: $wh-a;
    border-left: 1px solid shade($gy-a, 10%);
    border-right: 1px solid shade($gy-a, 10%);
    border-bottom: 1px solid shade($gy-a, 10%);
    border-radius: 0 0 8px 8px;
    overflow: hidden;
  }

  .col-bottom .row {
    margin-left: 0;
    margin-right: 0;
  }

  .col-bottom .item {
    height: px(51);
    background-color: $gy-a;
    border-top: 1px solid shade($gy-a, 10%);
  }

  .col-bottom .item.except {}

  .checkboxradio-wp {
    height: 100%;
  }

  .col-bottom .checkboxradio-wp label {
    font-size: px(11);
    text-transform: uppercase;
    font-weight: 400 !important;
    text-align: left;
  }

  .col-bottom .checkboxradio-wp .txt {
    font-size: px(11);
    display: block;
    padding-top: px(12) !important;
  }

  .item.except .txt {
    padding-top: px(7) !important;
  }

  .col-bottom .checkboxradio-wp .economy {
    font-size: px(11);
    display: block;
    text-transform: none;
    font-weight: 400;
    color: $rd-a;
  }

  .checkboxradio-wp .price {
    font-weight: 600 !important;
    font-size: px(15);
    display: inline-block;
    margin-top: 0;
  }

  .btn-wp {
    position: absolute;
    bottom: -60px;
    left: 0;
    width: 100%;
  }

  .btn-resa {
    @extend .btn-b;
    @extend .red;
    border-radius: 30px;
    text-transform: uppercase;
    font-weight: 600;
    padding: px(15);

    &:hover {
      background-color: black !important;
    }
  }

  .btn-resa .material-icons {
    font-size: px(16);
    vertical-align: middle;
    margin-left: 10px;
    position: relative;
    margin-top: -3px;
  }

  .btn-resa:focus,
  .btn-resa:hover {
    border-color: $rd-a !important;
    background-color: $rd-a !important;
  }

  .date-unavailable {
    position: relative;
    z-index: 99;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 100%;
    border-top: 1px solid shade($gy-a, 10%);
    padding: 15px;
    height: px(100);
    text-align: center;
    background-color: $gy-a;
    font-size: px(13);
  }

  .date-unavailable strong {
    color: $rd-a;
  }

  .item-except-price {
    background-color: $gy-a;
    padding: 13px 15px;
    text-align: center;
    font-size: px(11);
    border-top: 1px solid shade($gy-a, 10%);
  }

  .item-except-price strong {
    font-weight: 600;
    color: $rd-a;
    font-size: px(16);
    margin: 0 2px;
  }

  .item-except-price em {
    font-weight: 400;
    font-size: px(12);
  }
  @include media-breakpoint-down(lg) {
    .car-icon {
      font-size: px(9);
    }
  }
  @include media-breakpoint-down(md) {
    .car-icon {
      font-size: px(11);
    }
  }
  @include media-breakpoint-down(sm) {
    .content-txt {
      padding: px(15);
    }

    .category {
      left: px(15);
      top: px(15);
      font-size: px(9);
    }

    .discount {
      right: px(15);
      top: px(15);
      font-size: px(9);
    }

    .img-wp {
      padding: 15px 15px 0;
    }

    .title-car {
      font-size: px(13.5);
    }

    .col-bottom .checkboxradio-wp .txt {
      font-size: px(10);
    }

    .col-bottom .checkboxradio-wp .price {
      font-size: px(13.5);
    }

    .col-bottom .item.except .checkboxradio-wp .txt {
      padding-top: px(7) !important;
    }

    .col-bottom .checkboxradio-wp .txt {
      padding-top: px(15) !important;
    }
  }
}
// Reservaton :: Step 4

.resa-step-4 {
  position: relative;
  height: 100%;
  padding: 25px 30px;
  background-color: $wh-a;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  border-radius: 8px;

  .title-sub {
    color: $rd-a;
    text-transform: uppercase;
    font-size: px(16);
    font-weight: 600;
    border-bottom: 1px solid $rd-a;
    padding-bottom: 5px;
  }

  .list {
    margin-top: 20px;
  }

  .list li {
    display: block;
    position: relative;
    padding-left: 18px;
    font-size: px(14);
    line-height: 1.4em;
    word-break: break-word;
  }

  .list li:not(:last-child) {
    margin-bottom: 7px;
  }

  .list li:before {
    content: "";
    position: absolute;
    top: 9px;
    left: 0;
    width: 8px;
    height: 2px;
    background-color: $rd-a;
  }
}
// Reservation aside

.resa-aside-wp {
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  .header {
    background-color: $gy-d;
    position: relative;
    color: $wh-a;
    padding: px(13) px(70) px(13) px(15);

    .category {
      text-transform: uppercase;
      font-size: px(10);
      letter-spacing: 0.1em;
      font-weight: 300;
    }

    .title {
      margin-top: px(3);
      font-size: px(17);
      font-weight: 400;
      line-height: 1.1em;

      em {
        font-size: 80%;
        font-weight: 300;
      }
    }

    .btn-edition {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: px(55);
      background-color: rgba(0,0,0,0.15);
      color: $wh-a;
      padding-top: px(7);


      .material-icons {
        font-size: px(26);
      }
    }
  }

  .car-wp {
    padding-bottom: px(15);

    .icons-wp {
      padding: 0 px(20);

      .txt {
        font-size: px(10);
        display: block;
        line-height: 1.1em;
      }

      .car-icon {
        position: relative;
        padding: px(5) 0 px(5) px(17);

        .sprite {
          position: absolute;
          top: px(-1);
          left: px(-5);
        }
      }
    }
  }

  .options-wp {
    padding: px(10) px(20);
    background-color: $gy-e;

    .item {
      position: relative;
      padding: px(9) px(80) px(9) 0;
      font-size: px(13);
      color: $gy-b;
      line-height: 1.2em;

      .price {
        position: absolute;
        top: px(9);
        right: 0;
        font-weight: 500;
      }

      &.promo {
        position: relative;
        padding: 5px;
        background-color: rgba(0,0,0,0.05);
        border: 1px solid shade($gy-e, 10%);
        margin-bottom: px(10);
        line-height: 1.2em;

        .btn-promo {
          text-align: left;
          position: relative;
          display: block;
          width: 100%;
          font-style: italic;
          font-size: px(12);
          padding-left: px(20);

          .material-icons {
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -8px;
            font-size: px(15);
          }
        }
      }

      &.except {
        font-weight: 600;
        text-transform: uppercase;

        .price {
          font-weight: 600;
        }
      }
    }

    .item:not(:first-child) {
      border-top: 1px solid shade($gy-e, 10%);
    }

    &.step-3 {
      .price {
        font-weight: 400 !important;
        text-decoration: underline;
        text-transform: none;
        font-size: px(11);
      }

      .item:not(.except) {
        padding-right: 0;

        em {
          font-size: 95%;
          font-style: italic;
        }
      }
    }

    .txt-info {
      padding: px(9) 0;
      font-style: italic;
      border-top: 1px solid shade($gy-e, 10%);
      line-height: 1.2em;
      color: $gy-d;
      font-size: px(12);
    }
  }

  .btn-wp {
    display: block;
    width: 100%;
    text-transform: uppercase;
    font-size: px(16);
    padding: px(13);
    font-weight: 600;
    background-color: $rd-a;
    color: $wh-a;
    text-align: center;
    letter-spacing: 0.05em;

    > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
  @include media-breakpoint-down(md) {
    .header {
      padding-top: px(9);
      padding-bottom: px(9);

      .title {
        margin-top: 0;
      }
    }

    .content-wp {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      border: 1px solid $gy-a;
      border-radius: 0 0 8px 8px;

      .car-wp {
        flex: 0 0 45%;
        max-width: 45%;
        padding: 0 px(15) px(10);
      }

      .options-wp {
        flex: 0 0 55%;
        max-width: 55%;
        position: relative;
        z-index: 2;
        padding: px(10) px(25);

        .item {
          .price {
            top: px(9);
          }
        }
      }

      &:after {
        content: "";
        width: 55%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: $gy-e;
      }
    }

    .btn-wp {
      display: block;
      width: 240px;
      margin: px(15) auto 0;
      border-radius: 8px;
      padding-top: px(8);
      padding-bottom: px(8);
      font-size: px(14);
    }
  }
  @include media-breakpoint-down(xs) {
    .content-wp {
      display: block;

      .car-wp,
      .options-wp {
        display: block;
        width: 100%;
        max-width: 100%;
      }

      &:after {
        display: none;
      }
    }
  }
}

.btn-cgv {
  width: 100%;
  color: $gy-d;
  text-align: center;
  display: block;
  padding: px(10) 0;
  font-size: px(12);
  text-decoration: underline;
}
// Reservation options

.resa-included {
  border-bottom: 1px solid shade($gy-a, 10%);
  padding: 15px;
  background-color: $gy-a;
  border-radius: 8px;

  .item {
    line-height: 1.2em;
    position: relative;
    padding: px(3) 0 0 px(25);
    margin-bottom: px(5);
    font-size: px(15);
    font-weight: 500;
    color: $gy-b;
  }

  .item .material-icons {
    content: "";
    position: absolute;
    color: $gy-b;
    top: px(3);
    left: 0;
    font-size: px(18);
  }
}

.resa-section-wp {
  padding-left: 75px;
}

.resa-aside-wp {
  padding-right: 0;
}

.resa-step-2 {
  .item {
    border-bottom: 1px solid shade($gy-a,10%);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    width: 100%;
    color: $rd-a;
    height: 75px;
    font-size: px(14);
    margin-bottom: 10px;
    display: table;
    background-color: $gy-a;

    &.price-off {
      @include media-breakpoint-down(xs) {
        padding-bottom: 8px !important;

        &:after {
          display: none !important;
        }
      }
    }

    &.total {
      margin-top: px(30);
      background-color: $gy-d;
      border-bottom: 1px solid $gy-b;
      padding: px(8);

      &:after {
        display: none !important;
      }

      .title {
        color: $wh-a;
        text-transform: uppercase;
        font-weight: 500;
        padding-left: px(10);
      }

      .col-option-2 {
        border-radius: 5px;
        border: none;
        font-size: px(20);
        background-color: $wh-a;
        font-weight: 700;

        &:before {
          display: none;
        }
      }
      @include media-breakpoint-down(xs) {
        margin-top: px(20);

        .col-option-1 {
          padding-left: 0 !important;

          .title {
            font-size: px(15);
          }
        }

        .col-option-2 {
          width: 110px !important;
          height: 57px !important;
          padding-top: px(0);

          .price {
            font-size: 18px;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .more-info-trigger {
      position: absolute;
      top: 50%;
      right: 10px;
      z-index: 2;
      margin-top: -9px;
      color: $gy-b;

      .material-icons {
        font-size: px(18);
      }
    }

    .col-option-1,
    .col-option-2 {
      display: table-cell;
      padding: 0 15px;
      vertical-align: middle;
    }

    .col-option-1 {
      padding: 8px 35px 8px 8px;
      font-weight: 700;
    }

    .checkboxradio-wp {
      display: table;
      width: 100%;

      > input[type=checkbox] + label {
        font-size: px(14.5);
        height: 56px;
        width: 100%;
        display: table-cell;
        font-weight: 500;
        color: $gy-b;
        line-height: 1.15em;
        background-image: url("../images/icons/sprite-checkbox_radio-step2_3.png");
        margin-bottom: 0;
        vertical-align: middle;
        padding: 0 0 0 70px;
        background-size: 56px;
        em {
          display: block;
          font-weight: 300;
          font-size: 85%;
          color: $gy-b !important;
          margin-top: px(3);
        }
        @include media-breakpoint-down(lg) {
          font-size: px(13);
        }
        @include media-breakpoint-down(sm) {
          font-size: px(15);
        }
        @include media-breakpoint-down(xs) {
          font-size: px(14);
        }
      }

      > input[type=checkbox] + label {
        background-position: 0 bottom;
      }

      > input[type=checkbox]:checked + label {
        background-position: 0 top;
        color: $rd-a;
      }
    }
  }

  .quantite, .choix {
    padding: 0 0 0 70px;

    .selectpicker-wp {
      display: inline-block;

      .bootstrap-select .btn.dropdown-toggle {
        border-radius: 6px;
        padding: 5px 10px !important;
        border: $wh-a !important;
        background-color: $wh-a;

        .filter-option-inner-inner {
          font-size: px(14);
          text-align: center;
        }
      }

    }
    .label {
      color: $gy-b;
      font-weight: normal;
      padding-right: 10px;
    }
    &.disabled .selectpicker-wp .bootstrap-select .btn.dropdown-toggle,
    &.disabled .label {
      opacity: 0.4;
    }
  }

}

.car-outclass {
  position: relative;
  border: 1px solid shade($gy-a,10%);
  border-radius: 8px;
  margin-top: px(70);

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 30%;
    height: 1px;
    z-index: 2;
    left: 35%;
    top: -35px;
    background-color: shade($gy-a,10%);
  }
  @include media-breakpoint-down(xs) {
    margin-top: px(40);

    &:before {
      top: -20px;
    }
  }

  .header {
    background-color: $gy-a;
    border-bottom: 1px solid shade($gy-a,10%);
    padding: 20px;
    border-radius: 8px 8px 0 0;
    @include media-breakpoint-down(xs) {
      padding: 15px;

      .title {
        font-size: px(18);
      }
    }
  }

  .content-wp {
    .col-left {
      width: 250px;
      float: left;
    }

    .col-right {
      width: calc(100% - 250px);
      padding-top: 10px;
      float: left;
      padding-left: 35px;
    }

    .category {
      display: inline-block;
      line-height: 1em;
      font-size: px(11);
      font-weight: 300;
      background-color: $gy-a;
      padding: 5px 10px;
      border-radius: 15px;
    }

    .similar {
      display: inline-block;
      font-style: italic;
      font-weight: 400;
      margin-left: 5px;
      font-size: px(11);
    }
    @include media-breakpoint-down(sm) {
      .col-left {
        width: 200px;
      }

      .col-right {
        width: calc(100% - 200px);
      }
    }
    @include media-breakpoint-down(xs) {
      padding: 0 20px 20px;

      .col-left {
        width: 100%;
      }

      .col-right {
        padding: 0;
        width: calc(100% - 0px);
      }
    }
  }
}
// Step 3

.step-3-input {
  background-color: $gy-a;
  padding: px(10) px(15);
  border-radius: 8px;
  border-bottom: 1px solid shade($gy-a, 10%);

  label {
    text-transform: uppercase;
    font-weight: 500;
    font-size: px(12);
  }

  input,
  textarea {
    outline: none !important;
    box-shadow: none !important;
    border: none;
  }
}

.btn-payment {
  position: relative;
  background-color: $rd-a;
  color: $wh-a;
  border-radius: 10px;
  overflow: hidden;
  text-transform: uppercase;
  padding: px(10) px(20) px(10) px(75);

  .icon-left {
    position: absolute;
    height: 100%;
    width: 50px;
    padding-top: px(12);
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.1);
  }

  .icon-right,
  .txt {
    display: inline-block;
    vertical-align: middle;
  }

  .icon-right {
    position: relative;
    top: 3px;
  }
}

.title-wp {
  position: relative;
  display: inline-block;
  padding-right: 30px;

  .more-info-trigger {
    top: px(4);
    right: 0;
    position: absolute;

    .material-icons {
      font-size: px(18);
    }
  }
}
