.item-services {
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  .title {
    font-size: px(20);
    font-weight: 500;
    padding-top: px(15);
  }
  .desc {
    line-height: 1.3em;
    font-size: px(13);
    margin-top: px(5);
    padding-bottom: px(10);
  }
  @include media-breakpoint-down(xs) {
    .title {
      font-size: px(18);
    }
  }
}
